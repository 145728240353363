@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.floating { 
  animation-name: floating; 
  animation-duration: 3s; 
  animation-iteration-count: infinite; 
  animation-timing-function: ease-in-out;
} 
@keyframes floating { 
  0% { transform: translate(0, 0px); } 
  50% { transform: translate(0, 8px); } 
  100% { transform: translate(0, -0px); }  
} 
.floating-4 { 
  animation-name: floating; 
  animation-duration: 4s; 
  animation-iteration-count: infinite; 
  animation-timing-function: ease-in-out;
} 
@keyframes floating-4 { 
  0% { transform: translate(0, 0px); } 
  50% { transform: translate(0, 8px); } 
  100% { transform: translate(0, -0px); }  
}
